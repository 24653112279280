<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="queue_24px">
      <path
        id="icon/av/queue_24px"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 2H20C21.1 2 22 2.9 22 4V16C22 17.1 21.1 18 20 18H8C6.9 18 6 17.1 6 16V4C6 2.9 6.9 2 8 2ZM2 6H4V20H18V22H4C2.9 22 2 21.1 2 20V6ZM20 16H8V4H20V16ZM15 15H13V11H9V9H13V5H15V9H19V11H15V15Z"
        fill="black"
        fill-opacity="0.54"
      />
    </g>
  </svg>
</template>
    
    <script>
/**
 * @fileoverview Queue Icon
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

export default {
  name: "QueueIcon",
};
</script>
    
<style lang="scss" scoped>
</style>